import React from 'react';
import { Link, graphql } from 'gatsby';
import { SEO, Container, Banner, KitchenStyles, FadeIn } from 'components';
import styled from 'styled-components';

export const query = graphql`
  query KitchensPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    bannerImage: file(relativePath: { eq: "assets/images/kitchens-banner.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 90, layout: FULL_WIDTH)
      }
    }
  }
`;

const ProjectsPage = (props) => {
  const { data } = props;
  const { site } = data || {};
  const { bannerImage } = data;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }
  const banner = {
    caption: 'Our Kitchen Styles',
    text: 'To view a selection of our recent kitchen projects below, simply select a style and have a browse.',
    img: bannerImage
  };

  return (
    <main>
      <SEO
        title="Kitchen Styles"
        description="Find your perfect kitchen style. Have a browse through our recent kitchen projects to find a style that suits you."
        keywords={site.keywords}
      />
      <Banner banner={banner} />
      <Container>
        <FadeIn>
          <KitchenStyles />
        </FadeIn>
      </Container>
    </main>
  );
};

export default ProjectsPage;
